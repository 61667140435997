<template>
  <div>
    <MainCard v-if="!isLoadingTable">
      <template v-slot:body>
        <DataTablePagination
          :total-items="totalItems"
          :no-add-button="noAddButton"
          :no-search-input="noSearchInput"
          :history-book-button="true"
          :filter-book-purchase-history="filter"
          :headers="headers"
          :items="books"
          :items-type="types"
          :items-packet="packets"
          :search="search"
          :total-pages.sync="totalPages"
          title="Buku"
          subtitle="Halaman untuk melihat buku yang sudah dibeli"
          :is-loading="isLoadingData"
          @page="page"
          @live-search="searchHandler"
          @change-page="paginationHandler"
          @history-book-purchase="showHistory"
          @filter-packet="handleFilterPacket($event)"
          @filter-type="handleFilterType($event)"
        >
        </DataTablePagination>
      </template>
    </MainCard>
    <SkeletonLoaderTable v-else></SkeletonLoaderTable>
    <ModalDialog
      :visible="modalDialog"
      header="Riwayat Pembelian Buku"
      :width="800"
      :hidden-actions="false"
      @close-modal="modalDialog = false"
    >
      <template v-slot:body>
        <v-row>
          <v-col
            v-for="bookPurchaseHistory,index in bookPurchaseHistories"
            :key="index"
            md="6"
          >
            <v-card outlined>
              <v-card-title>{{ bookPurchaseHistory.name }}</v-card-title>
              <v-card-text>
                <div class="d-flex align-center">
                  <div>
                    <p class="font-weight-light">
                      Tanggal Beli
                    </p>
                    <p class="font-weight-light">
                      Deskripsi
                    </p>
                    <p class="font-weight-light">
                      Harga
                    </p>
                    <p class="font-weight-light mb-0">
                      Jumlah Buku
                    </p>
                  </div>
                  <div class="ms-4">
                    <p class="font-weight-medium">
                      : {{ getFormatDate(bookPurchaseHistory.created_at) }}
                    </p>
                    <p class="font-weight-medium">
                      : {{ bookPurchaseHistory.description }}
                    </p>
                    <p class="font-weight-medium">
                      : Rp. {{ bookPurchaseHistory.price }}
                    </p>
                    <p class="font-weight-medium mb-0">
                      : {{ bookPurchaseHistory.total_book }}
                    </p>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </template>

    </ModalDialog>
  </div>
</template>

<script>
import DataTablePagination from '@/views/components/DataTablePagination.vue'
import MainCard from '@/views/components/MainCard.vue'
import SkeletonLoaderTable from '@/views/components/SkeletonLoaderTable.vue'
import ModalDialog from '@/views/components/ModalDialog.vue'

export default {
  name: 'Book',
  components: {
    DataTablePagination,
    MainCard,
    SkeletonLoaderTable,
    ModalDialog,
  },
  data() {
    return {
      headers: [
        { text: 'No', value: 'index' },
        { text: 'Nama Buku', value: 'name' },
        { text: 'Tipe Buku', value: 'type' },
      ],
      books: [],
      packets: [],
      types: [
        { name: 'Ebook', value: 'ebook' },
        { name: 'Video', value: 'video' },
        { name: 'Audio', value: 'audio' },
      ],
      type: '',
      bookPurchaseHistories: [],
      page: 1,
      totalItems: 0,
      totalPages: 0,
      isLoadingData: false,
      isLoadingTable: true,
      noAddButton: true,
      noSearchInput: false,
      modalDialog: false,
      filter: true,
      search: '',
      filterQuery: {
        search: '',
      },
      school_uuid: this.getSchoolUuid(),
      package_school_uuid: '',
    }
  },
  watch: {
    page: {
      handler() {
        this.listBook()
      },
    },
  },
  mounted() {
    this.getSchoolUuid()
    this.listBook()
    this.listBookPurchaseHistory()
  },
  methods: {
    getSchoolUuid() {
      this.school = JSON.parse(localStorage.getItem('user')).user.details
      this.school.forEach(el => {
        if (el.school === undefined) {
          this.school_uuid = el.uuid
          this.user_uuid = JSON.parse(localStorage.getItem('user')).user.uuid

          return
        }
        if (el.school === null) {
          this.showSnackbar({
            text: 'you dont have access to school forum',
            color: 'error',
          })

          return
        }
        this.school_uuid = el.school.uuid
      })

      return true
    },
    async listBook(params = {}) {
      this.isLoadingData = true
      await this.$services.ApiServices.getParams('library', this.school_uuid, {
        ...params,
        page: this.page,
        package_school_uuid: this.package_school_uuid,
        type: this.type,
      }).then(({ data }) => {
        this.books = data.data.map((book, index) => ({
          ...book,
          index: index + 1,
          type: book.type,
        }))
        this.totalItems = data.meta.total
        this.totalPages = data.meta.last_page
      })
      this.isLoadingData = false
      this.isLoadingTable = false
    },
    async listBookPurchaseHistory(params = {}) {
      await this.$services.ApiServices.list('packageschool', { ...params, school_uuid: this.school_uuid }).then(
        ({ data }) => {
          this.bookPurchaseHistories = data.data
          this.packets = this.bookPurchaseHistories
        },
        err => {
          console.error(err)
        },
      )
    },
    showHistory() {
      this.modalDialog = true
      this.listBookPurchaseHistory()
    },
    showBook(uuid) {
      this.modalDialog = false
      console.log(uuid)
    },
    handleFilterPacket(uuid) {
      this.package_school_uuid = uuid
      if (uuid === null) {
        this.package_school_uuid = ''
        return this.listBook()
      }
      return this.listBook()
    },
    handleFilterType(type) {
      this.type = type
      if (type === null) {
        this.type = ''
        return this.listBook()
      }
      this.listBook()
    },
    searchHandler(search) {
      console.log(search)
      this.filterQuery.search = search
      this.page = 1
      this.listBook(this.filterQuery)
    },
    paginationHandler(pageNumber) {
      this.page = pageNumber
    },
    getFormatDate(date) {
      const createdDate = new Date(date)
      const day = `0${createdDate.getDate()}`
      const month = `0${createdDate.getMonth() + 1}` // months are zero based
      const year = createdDate.getFullYear()
      return `${day.slice(-2)}/${month.slice(-2)}/${year}`
    },
  },
}
</script>

<style>
</style>
